<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="1000">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title> سجل التحديث </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-card class="pa-4" outlined>
            <v-row align="center">
              <v-col cols="12" md="3">
                <h3>الـمـصــــــــــــــــــدر:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>{{ item.source }}</h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>الــــــعـــــــنـــــــــــوان:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    {{ item.title }}
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>الـجـهــــــــــــــــــة:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    <!-- {{ item.ministry.name }} -->
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>رقـم الكـتـــــــــاب:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    {{ item.number }}
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>تاريـخ الكتــــــــاب:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    <span dir="ltr">{{
                      $service.formatDate(new Date(item.date), false)
                    }}</span>
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>رقـم الـــــــــوارد:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    {{ item.sec_number }}
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>تاريـخ الـــــــــــــــوارد:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    <span dir="ltr">{{
                      $service.formatDate(new Date(item.sec_date), false)
                    }}</span>
                  </h3></v-chip
                >
              </v-col>
              <v-col cols="12" md="3">
                <h3>العناوين الفرعية:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip
                  class="mx-1"
                  color="primary"
                  v-for="(chip, i) in item.tags.split(',')"
                  :key="i"
                >
                  {{ chip }}
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>وصــــــــــــــــــف :</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label
                  ><h3>
                    {{ item.note }}
                  </h3></v-chip
                >
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: {},
    };
  },

  mounted() {
    this.reset();

    this.$eventBus.$on("fill-fields", () => {
      console.log(this.$store.state.itemDetails);
      this.item = this.$store.state.itemDetails;
    });
  },

  methods: {
    reset() {
      this.item = {
        title: "",
        number: "",
        date: "2022-06-23",
        sec_number: "",
        ministryId: "",
        sec_date: "2022-06-23",
        type: 0,
        note: "",
        tags: "",
        source: "",
        destination: "",
        outgoing: "",
        userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        attachments: [],
      };
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>