<template>
  <div>
    <v-card outlined>
      <v-card-title>
        {{ $t("tfasyl-alktab") }}
        <v-spacer></v-spacer>
        <v-btn :to="'/documents/edit/' + document.id" color="info">
          <v-icon>mdi-pen</v-icon>
          {{ $t("edit") }}
        </v-btn>
        <v-btn :to="'/documentsShare/' + document.id" color="secondary" class="mx-2">
          <v-icon>mdi-share-all</v-icon>
          {{ $t("msharkh-ktab") }}
        </v-btn>
        <v-btn :to="'/documentsComment/' + document.id" color="primary">
          <v-icon>mdi-message-reply-text</v-icon>
          {{ $t("alhwamsh") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-container fluid>
        <v-row>
          <v-col cols="8">
            <v-row align="center">
              <v-col cols="12" md="3">
                <h3>الــــــعـــــــنـــــــــــوان:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3>{{ document.title }}</h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>الاهـــــــمـــــــية:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3>{{ priorities.find((v) => v.id == document.priority).text }}</h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>الـجـهــــــــــــــــــة:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3 v-if="document.ministry">{{ document.ministry.name }}</h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>رقـم الكـتـــــــــاب:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3>{{ document.number }}</h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>تاريـخ الكتــــــــاب:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3>
                    <span dir="ltr">
                      {{
                        document.date.substr(0,10)
                      }}
                    </span>
                  </h3>
                </v-chip>
              </v-col>
              <v-col v-if="document.type != 2 || document.type != 1" cols="12" md="3">
                <h3>رقـم الـــــــــوارد:</h3>
              </v-col>
              <v-col v-if="document.type != 2 || document.type != 1" cols="12" md="9">
                <v-chip label>
                  <h3>{{ document.sec_number.substr(0,10) }}</h3>
                </v-chip>
              </v-col>
              <v-col v-if="document.type != 2 || document.type != 1" cols="12" md="3">
                <h3>تاريـخ الـــــــــــــــوارد:</h3>
              </v-col>
              <v-col v-if="document.type != 2 || document.type != 1" cols="12" md="9">
                <v-chip label>
                  <h3>
                    <span dir="ltr">
                      {{
                        document.sec_date.substr(0,10)
                      }}
                    </span>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>العناوين الفرعية:</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip
                  class="mx-1"
                  color="primary"
                  v-for="(chip, i) in document.tags.split(',')"
                  :key="i"
                >{{ chip }}</v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>نوع الكتـــــــــــــــاب :</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3 v-if="document.documentCategory != null">{{ document.documentCategory.name }}</h3>
                </v-chip>
              </v-col>
              <v-col cols="12" md="3">
                <h3>وصــــــــــــــــــف :</h3>
              </v-col>
              <v-col cols="12" md="9">
                <v-chip label>
                  <h3 v-html="document.note"></h3>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-card-title>{{ $t("almrfqat") }}</v-card-title>
              <v-card-subtitle>
                <div class="d-flex align-center mt-3">
                  <v-icon color="primary" class="mb-3">mdi-information-outline</v-icon>
                  <p class="mx-2" style="padding: 0">
                    <span class="font-weight-bold">الملفات المسموحة :</span>
                    jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx, ppt,
                    pptx, txt, zip, rar.
                  </p>
                </div>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-card
                  class="mt-1"
                  outlined
                  v-for="(file, index) in document.attachments"
                  :key="index"
                >
                  <v-card-title>
                    {{ file.path.split("/").pop() }}
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="
                        fileExt(file.path) == 'jpg' ||
                        fileExt(file.path) == 'png' ||
                        fileExt(file.path) == 'jpeg' ||
                        fileExt(file.path) == 'gif' ||
                        fileExt(file.path) == 'fav' ||
                        fileExt(file.path) == 'ico' ||
                        fileExt(file.path) == 'pdf'
                          ? view(file.path)
                          : downloadURI(
                              $config.BASE_URL_PROD + '/' + file.path,
                              document.title
                            )
                      "
                      color="primary"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-card-title>
                </v-card>
              </v-card-text>
            </v-card>
            <v-card class="mt-5" outlined>
              <v-card-title>سجل التحديثات</v-card-title>
              <v-card-subtitle>
                <div class="d-flex align-center mt-3">
                  <v-icon color="primary" class="mb-3">mdi-information-outline</v-icon>
                  <p class="mx-2" style="padding: 0">
                    <span class="font-weight-bold">اخر التعديلات التي تمت على هذا الكتاب</span>
                  </p>
                </div>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-card outlined v-for="log in logs" :key="log.id">
                  <v-card-title>
                    بتاريخ
                    <span
                      class="font-weight-bold mx-2"
                    >{{ $service.formatDate(log.created, false) }}</span>
                  </v-card-title>
                  <v-container fluid>
                    <h3>
                      تمت المشاركة الى :
                      <v-chip>
                        {{
                        log.userFullName != null
                        ? log.userFullName
                        : log.organizationName
                        }}
                      </v-chip>
                    </h3>
                  </v-container>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog width="900" :scrollable="false" v-model="viewDialog">
      <v-card>
        <v-card-title>
          {{ file.split("/").pop().split("_").pop() }}
          <v-spacer></v-spacer>
          <!-- <v-btn color="success" @click="downloadURI($config.BASE_URL_PROD + '/' + file, document.title)">
            <v-icon>mdi-download</v-icon>تنزيل
          </v-btn> -->
          <v-btn color="success" @click="downloadFile(file)">
            <v-icon>mdi-download</v-icon>تنزيل
          </v-btn>
          <!-- <v-btn
            color="primary"
            class="mx-2"
            v-print="printObj"
          >
            <v-icon>mdi-printer</v-icon>
            طباعة
          </v-btn>-->
          <v-btn color="error" text @click="viewDialog = false">
            <v-icon>mdi-close</v-icon>اغلاق
          </v-btn>
        </v-card-title>
        <v-container grid-list-xs>
          <v-img
            lazy-src="@/assets/images/A_black_image.jpg.webp"
            id="img"
            height="600px"
            :src="$config.BASE_URL_PROD + '/' + file"
            :alt="file.split('/').pop()"
            v-if="
              fileExt(file) == 'jpg' ||
              fileExt(file) == 'png' ||
              fileExt(file) == 'jpeg' ||
              fileExt(file) == 'gif' ||
              fileExt(file) == 'fav' ||
              fileExt(file) == 'ico'
            "
            contain
          >
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular size="50" color="white" indeterminate></v-progress-circular>
              </div>
            </template>
          </v-img>
          <pdf :src="$config.BASE_URL_PROD + '/' + file" v-if="fileExt(file) == 'pdf'" id="img"></pdf>
        </v-container>
      </v-card>
    </v-dialog>
    <log-dialog />
  </div>
</template>
<script>
import LogDialog from "./logDialog.vue";
import print from "vue-print-nb";
export default {
  directives: {
    print
  },
  components: {
    LogDialog
  },
  data() {
    return {
      printObj: {
        id: "img",
        popTitle: "good print",
        extraCss:
          "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      viewDialog: false,
      document: {},
      logs: [],
      priorities: [
        { text: "انتباه", id: 0 },
        { text: "عاجل", id: 1 },
        { text: "عادي", id: 2 },
        { text: "سري", id: 3 }
      ],
      types: [
        { text: "اعمام", id: 1 },
        { text: "مخصصات", id: 2 },
        { text: "امر وزاري", id: 3 },
        { text: "امر اداري", id: 4 },
        { text: "شكر وتقدير", id: 5 }
      ],
      file: ""
    };
  },
  methods: {
    fileExt(file) {
      return file
        .split(".")
        .pop()
        .toLowerCase();
    },
    view(file) {
      if (
        this.fileExt(file) == "jpg" ||
        this.fileExt(file) == "png" ||
        this.fileExt(file) == "jpeg" ||
        this.fileExt(file) == "gif" ||
        this.fileExt(file) == "fav" ||
        this.fileExt(file) == "ico"
      ) {
        this.file = file;
        this.viewDialog = true;
      } else {
        console.log(
          this.$store.state.config.PRODUCTION_API + "/" + file,
          "_blank"
        );
        window.open(
          this.$store.state.config.PRODUCTION_API + "/" + file,
          "_blank"
        );
      }
    },
    // downloadURI(url, filename) {
    //   fetch(url)
    //     .then(response => response.blob())
    //     .then(blob => {
    //       const link = document.createElement("a");
    //       link.href = URL.createObjectURL(blob);
    //       link.download = filename;
    //       link.click();
    //     })
    //     .catch(console.error);
    // },
    downloadFile(file) {
    const url = `${this.$config.BASE_URL_PROD}/${file}`;
    const filename = document.title || 'download';

    this.downloadURI(url, filename);
  },
  downloadURI(url, filename) {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // Clean up
        URL.revokeObjectURL(link.href);
      })
      .catch(error => {
        console.error('There was an error with the download:', error);
      });
  },
    async getDocument() {
      this.$global.state.loading = true;
      let res = await this.$http.get("/document/", {
        params: {
          id: this.$route.params.id
        }
      });
      this.document = res.data.result[0];
      this.getLog();
      this.$global.state.loading = false;
    },
    async getLog() {
      this.$global.state.loading = true;
      let res = await this.$http.get("/documentShare/", {
        params: {
          DocumentId: this.$route.params.id
        }
      });
      this.logs = res.data.result;
      this.$global.state.loading = false;
    },
    showLog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    }
  },
  mounted() {
    this.getDocument();
  }
};
</script>
